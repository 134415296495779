import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

// Fetch subscribers
export const getSubscribers = createAsyncThunk('subscribers/getSubscribers', async () => {
  const response = await fetch('https://blopia.ng/api/v1/subscribe/all-subscribers');
  const data = await response.json();
  return data;
});

export const sendBulkEmail = ({ subject, template }) => async (dispatch) => {
    try {
      const response = await axios.post("https://blopia.ng/api/v1/subscribe/send-emails", { subject, template });
      if (response.status === 200) {
        toast.success("Bulk email sent successfully!");
      }
      
    } catch (error) {
      toast.error("Failed to send bulk email.");
      console.error(error);
    }
  };