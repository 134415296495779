import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/index";
import { GoogleOAuthProvider } from '@react-oauth/google';


const clientId = '747578606736-n377ga046hn681vhgr3pavq9tvgob0s2.apps.googleusercontent.com'
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store }>
    
  <BrowserRouter>
    <React.StrictMode>
    <GoogleOAuthProvider clientId={clientId}>
      <App />
  </GoogleOAuthProvider>,
    </React.StrictMode>
  </BrowserRouter>
  </Provider>
);


