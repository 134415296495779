
import MainFooter from "./main-footer/MainFooter"
import MainNavigation from "./main-navigation/MainNavigation";
import FooterLinks from './main-footer/FooterLinks'
const Layout = (props) => {
    return ( 
        <div>
            <MainNavigation />
            <main>{props.children}</main>
           
            <FooterLinks/>
            <MainFooter />
        </div>
     );
}
 
export default Layout;