import { createSlice } from "@reduxjs/toolkit";

const initialFilterState = {
  filteredProducts: [],
};

const filterSlice = createSlice({
  name: "filter",
  initialState: initialFilterState,
  reducers: {
    FILTER_BY_SEARCH(state, action) {
      const { products, search } = action.payload;
      const tempProducts = products?.filter((product) => {
        return (
          product.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
          product.category?.toLowerCase()?.includes(search?.toLowerCase())
        );
      });
      state.filteredProducts = tempProducts;
    },
    setSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
    SORT_PRODUCTS(state, action) {
      const { products, sort } = action.payload;

     const prod = [...products]

      
      let tempProducts = [];

      if (sort === "latest") {
        tempProducts = products;
      }
      if (sort === "lowest-price") {
        tempProducts = prod.sort((a, b) => (a.price > b.price ? 1 : -1));
      }
      if (sort === "highest-price") {
        tempProducts = prod.sort((a, b) => {
          return b.price - a.price ? -1 :1;
        });
      }
      if (sort === "a-z") {
        tempProducts = prod.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      }
      if (sort === "z-a") {
        tempProducts = prod.sort((a, b) => {
          return b.name.localeCompare(a.name);
        });
      }
      state.filteredProducts = tempProducts;
    },
    FILTER_BY_CATEGORY(state, action) {
      const { products, category } = action.payload;

      const prod = [...products,category]

      let tempProducts = [];

      if (category === "All") {
        tempProducts = prod;
      } else {
        tempProducts = prod?.filter((product) => {
          return product.category === category;
        });
      }
      state.filteredProducts = tempProducts;
    },
    FILTER_BY_BRAND(state, action) {
      const { products, brand } = action.payload;
      const prod = [...products,brand]
      let tempProducts = [];
      if (brand === "All") {
        tempProducts = prod;
      } else {
        tempProducts = prod.filter((product) => {
          return product.brand === brand;
        });
      }
      state.filteredProducts = tempProducts;
    },
    //this is for the SLIDER COMPONENET
    // FILTER_BY_PRICE(state,action){
    //   const {products,price} = action.payload;
    //   let tempProducts = [];
    //    tempProducts = products.filter((product)=>{
    //     return product.price >= price[0] && product.price <= price[1]
    //   })
    //   state.filteredProducts = tempProducts;
    // }
    FILTER_BY_PRICE(state, action) {
      const { products, price } = action.payload;
      const prod = [...products,price]
      let tempProducts = [];
      tempProducts = prod.filter((product) => {
        return product.price <= price;
      });
      state.filteredProducts = tempProducts;
    },
  },
});

export const filterActions = filterSlice.actions;
export default filterSlice;