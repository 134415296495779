import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const AdminOnlyRoute = (props) => {
  const { user } = useSelector((state) => state.auth);
  console.log(user);

  if (user?.role === "admin" || user?.name === "admin") {
    return props.children;
  }

  return (
    <section style={{ height: "80vh" }}>
      <div className="container">
        <h2>Permission Denied</h2>
        <p>Vendors only can access this page </p>
        <br />
        <Link to={"/"}>
          <button className="--btn"> Back To Home</button>
        </Link>
      </div>
    </section>
  );
};


export const AdminOnlyLink = (props) => {
    const { user } = useSelector((state) => state.auth);

    if (user && user.name === "admin" && user.role === "admin") {
        return props.children;
    } else {
        return null;
    }
}


export default AdminOnlyRoute;
