import React from 'react';
import { RiWhatsappFill } from 'react-icons/ri';
import './WhatsAppButton.css'; // Import the CSS file

const WhatsAppButton = () => {
  return (
    <div className="whatsapp">
      <a href="https://api.whatsapp.com/send?phone=23407025797147" target="_blank">
        <RiWhatsappFill size={50} color="#1ece0e" className="mouse" />
      </a>
      <p>whatsapp</p>
    </div>
  );
};

export default WhatsAppButton;
