
import { createSlice } from '@reduxjs/toolkit';
import { getSubscribers } from './subscriberActions';

const initialState = {
  subscribers: [],
  isLoading: false,
  error: null,
};

const subscriberSlice = createSlice({
  name: 'subscribers',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getSubscribers.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSubscribers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subscribers = action.payload;
      })
      .addCase(getSubscribers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default subscriberSlice.reducer;
