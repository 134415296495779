import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import productService from "./productService";
import { toast } from "react-toastify";

const initialProductState = {
  product: null,
  products: [], // Ensure products is an array
  minPrice: null,
  maxPrice: null,
  totalStoreValue: 0,
  outOfStock: 0,
  category: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const createProduct = createAsyncThunk(
  "products/createProduct",
  async (formData, thunkApi) => {
    try {
      return await productService.createProduct(formData);
    } catch (error) {
      const message = 
        (error.response && error.response.data && error.response.data.msg) || 
        error.msg || 
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getProducts = createAsyncThunk(
  "products/getProducts",
  async (_, thunkApi) => {
    try {
      // Check local storage first
      const cachedProducts = JSON.parse(localStorage.getItem("products"));
      if (Array.isArray(cachedProducts)) {
        return { products: cachedProducts };
      }

      // If no valid cached products, fetch from API
      const products = await productService.getProducts();
      localStorage.setItem("products", JSON.stringify(products));
      return products;
    } catch (error) {
      const message = 
        (error.response && error.response.data && error.response.data.msg) || 
        error.msg || 
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "products/deleteProduct",
  async (id, thunkApi) => {
    try {
      return await productService.deleteProduct(id);
    } catch (error) {
      const message = 
        (error.response && error.response.data && error.response.data.msg) || 
        error.msg || 
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getSingleProduct = createAsyncThunk(
  "products/getSingleProduct",
  async (id, thunkApi) => {
    try {
      return await productService.getSingleProduct(id);
    } catch (error) {
      const message = 
        (error.response && error.response.data && error.response.data.msg) || 
        error.msg || 
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async ({ id, formData }, thunkApi) => {
    try {
      return await productService.updateProduct(id, formData);
    } catch (error) {
      const message = 
        (error.response && error.response.data && error.response.data.msg) || 
        error.msg || 
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

// Utility function to update local storage
const updateLocalStorage = (products) => {
  localStorage.setItem("products", JSON.stringify(products));
};

const productSlice = createSlice({
  name: "product",
  initialState: initialProductState,
  reducers: {
    RESET_PRODUCT(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
    GET_PRICE_RANGE(state, action) {
      const { products } = action.payload;
      let array = [];
      products.forEach((product) => {
        const price = product.price;
        array.push(price);
      });
      const max = Math.max(...array);
      const min = Math.min(...array);

      state.maxPrice = max;
      state.minPrice = min;
    },
  },
  extraReducers(builder) {
    builder
      // createProduct
      .addCase(createProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.isError = false;
        state.isSuccess = true;
        state.isLoading = false;
        state.message = "Product created successfully";
        state.products.push(action.payload.product); // Assuming the API returns the created product
        updateLocalStorage(state.products);
        toast.success(action.payload.msg);
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        toast.error(action.payload);
      })
      // getProducts
      .addCase(getProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.products = action.payload.products;
        updateLocalStorage(state.products);
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      // deleteProduct
      .addCase(deleteProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload.msg;
        state.products = state.products.filter((p) => p.id !== action.payload.id);
        updateLocalStorage(state.products);
        toast.success(action.payload.msg);
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload.msg);
      })
      // getSingleProduct
      .addCase(getSingleProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.product = action.payload; // to store the product we requested
      })
      .addCase(getSingleProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      // updateProduct
      .addCase(updateProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.message = "Product updated successfully";
        const index = state.products.findIndex((p) => p.id === action.payload.product.id);
        if (index !== -1) {
          state.products[index] = action.payload.product;
        }
        updateLocalStorage(state.products);
        toast.success(action.payload.msg);
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      });
  },
});

export const productActions = productSlice.actions;

export default productSlice;