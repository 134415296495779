import React, { useState } from "react";  
import styles from "./Auth.module.scss";  
import { MdPassword } from "react-icons/md";  
import { Link, useNavigate, useParams } from "react-router-dom";  
import { toast } from "react-toastify";  
import { useDispatch } from "react-redux";  
import { resetPassword } from "../../store/auth/authService";  
import Card from "../../components/ui/card/Card";  

const initialState = {  
  password: "",  
  password2: "",  
};  

const Reset = () => {  
  const [formData, setFormData] = useState(initialState);  
  const { password, password2 } = formData;  

  const { resetToken } = useParams();   
  const dispatch = useDispatch();  
  const navigate = useNavigate();  

  const handleInputChange = (e) => {  
    const { name, value } = e.target;  
    setFormData({ ...formData, [name]: value });  
  };  

  const reset = async (e) => {  
    e.preventDefault();  

    // Trim whitespace from passwords  
    const trimmedPassword = password.trim();  
    const trimmedPassword2 = password2.trim();  

    if (trimmedPassword.length < 8) {  
      return toast.error("Password must be at least 8 characters");  
    }  
    if (trimmedPassword !== trimmedPassword2) {  
      return toast.error("Passwords do not match");  
    }  

    const userData = {  
      password: trimmedPassword,  
    };  

    try {  
      const data = await resetPassword(userData, resetToken);  
      toast.success("Password reset successful");  
      navigate('/login');  
    } catch (error) {  
      toast.error(error.message || "Failed to reset password, please try again");  
    }  
  };  

  return (  
    <div className={`container ${styles.auth}`}>  
      <Card>  
        <div className={styles.form}>  
          <div className="--flex-center">  
            <MdPassword size={35} color="#999" />  
          </div>  
          <h2>Reset Password</h2>  

          <form onSubmit={reset}>  
            <input  
              type="password"  
              placeholder="New Password"  
              required  
              name="password"  
              value={password}  
              onChange={handleInputChange}  
            />  
            <input  
              type="password"  
              placeholder="Confirm New Password"  
              required  
              name="password2"  
              value={password2}  
              onChange={handleInputChange}  
            />  

            <button type="submit" className="--btn --btn-primary --btn-block">  
              Reset Password  
            </button>  
            <div className={styles.links}>  
              <p>  
                <Link to="/">- Home</Link>  
              </p>  
              <p>  
                <Link to="/login">- Login</Link>  
              </p>  
            </div>  
          </form>  
        </div>  
      </Card>  
    </div>  
  );  
};  

export default Reset;