// src/store/slider/sliderSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchSliderData = createAsyncThunk('slider/fetchSliderData', async () => {
    const response = await axios.get('https://blopia.ng/api/v1/slider/get-sliders');
    return response.data.sliders;  // Adjust this according to the actual API structure
  });
// Create slider
export const createSlider = createAsyncThunk('slider/createSlider', async (formData) => {
  const response = await axios.post('https://blopia.ng/api/v1/slider/create-slider', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
});

// Delete slider
export const deleteSlider = createAsyncThunk('slider/deleteSlider', async (id) => {
  await axios.delete(`https://blopia.ng/api/v1/slider/delete-slider/${id}`);
  return id;
});

const sliderSlice = createSlice({
  name: 'slider',
  initialState: {
    sliders: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSliderData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSliderData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.sliders = action.payload;
      })
      .addCase(fetchSliderData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createSlider.fulfilled, (state, action) => {
        state.sliders.push(action.payload);
      })
      .addCase(deleteSlider.fulfilled, (state, action) => {
        state.sliders = state.sliders.filter((slider) => slider._id !== action.payload);
      });
  },
});

export default sliderSlice.reducer;
